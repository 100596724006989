import React from 'react'

type Props = { isSaving: boolean }; /* could also use interface */

const SaveButton = (props: Props) =>
   <>
      {!props.isSaving &&
      <>
         <input
            type="submit"
            className="btn btn-primary"
            value="Save"
         />
      </>
      }
      {props.isSaving &&
      <>
         <button className="btn btn-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp; Saving...
         </button>
      </>
      }
   </>

export default SaveButton

