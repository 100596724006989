import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'


import axios from 'axios'

import { SearchResultDto } from './searchResult.dto'

interface ParamTypes {
  lotId: string
}

interface ParamTypes {
  lotId: string
}

const Search = () => {
   const { lotId } = useParams<ParamTypes>()
   const [search, setSearch] = useState<string>(lotId)
   const [searchResults, setSearchResults] = useState<SearchResultDto[]>()

   useEffect(
      () => {
         axios.get(`/search/${search}`)
            .then(response => setSearchResults(response.data))
            .catch(error => alert('Error loading stock options' + error))
      }, [search]
   )

   return (
      <>
         <h1>Search</h1>

         <div className="row">
            <div className="col">
               <label>Lot ID</label>
               <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSearch(e.target.value)
                     }
                  }
               />
            </div>
         </div>
         <br />
         <p>Batches that have used the Lot ID(s):</p>
         <br />
         <table className="table">
            <thead>
               <tr>
                  <th scope="col">Batch Name</th>
                  <th scope="col">Lot ID</th>
                  <th scope="col">Used</th>
                  <th scope="col">Time</th>
               </tr>
            </thead>
            <tbody>
               {searchResults && searchResults.map(
                  searchResult =>
                     <tr key={searchResult.batchId + searchResult.lotId}>
                        <th scope="row"><Link to={`/batchReadOnly/${searchResult.batchId}`}>{searchResult.batchName}</Link></th>
                        <td>{searchResult.lotId}</td>
                        <td>{searchResult.amount} {searchResult.unit}</td>
                        <td>{new Date(searchResult.batchTime).toLocaleDateString('en-AU')}</td>
                     </tr>
               )
               }
            </tbody>
         </table>
      </>
   )
}

export default Search
