import React, { useState, useEffect } from 'react'
import { useHistory, useParams, Link} from 'react-router-dom'

import DatePicker from 'react-datepicker'
import axios from 'axios'

import BatchStock, { BatchStockProps } from './BatchStock'
import BatchBatch, { BatchBatchProps } from './BatchBatch'
import { BatchDto, StockOptionDto, BatchOptionDto } from './batch.dto'

import ErrorMessage from '../ErrorMessage'
import SaveButton from '../SaveButton'

type BatchFormProps = {
 readOnly: boolean
}
interface ParamTypes {
  id: string
}

const BatchForm = (props: BatchFormProps) => {
   const now = new Date()
   const monthLetters = 'ABCDEFGHIJKL'
   const batchName = monthLetters[now.getMonth()] + now.getFullYear().toString().substr(-2) + ('0' + now.getDate()).slice(-2)

   const [batch, setBatch] = useState<Partial<BatchDto>>({ name: batchName, startTime: new Date() })

   const [errorMessage, setErrorMessage] = useState<string>()
   const [stockOptions, setStockOptions] = useState<StockOptionDto[]>()
   const [batchOptions, setBatchOptions] = useState<BatchOptionDto[]>()

   const [isSaving, setIsSaving] = useState<boolean>(false)
   const history = useHistory()
   const { id } = useParams<ParamTypes>()

   const addBatchStock = () => {
      const newBatchStock = { storedIndex: -1, readOnly: props.readOnly, stockOptions: stockOptions, stockOption: { value: -1, label: '', amount: 0, unit: '' }, amount: 0, deleteHandler: deleteBatchStock, updateHandler: updateBatchStock }
      setBatchStock(original => [...original, newBatchStock])
   }

   const deleteBatchStock = (index: number): void => {
      setBatchStock(original => original.filter((_, i) => i !== index))
   }

   const updateBatchStock = (index: number, batchStockProps: BatchStockProps): void => {
      setBatchStock([...batchStock.slice(0, index), batchStockProps, ...batchStock.slice(index + 1)])
   }

   const addBatchBatch = () => {
      const newBatchBatch = { storedIndex: -1, readOnly: props.readOnly, batchOptions: batchOptions, batchOption: { value: -1, label: '', amount: 0 }, amount: 0, deleteHandler: deleteBatchBatch, updateHandler: updateBatchBatches }
      setBatchBatches(original => [...original, newBatchBatch])
   }

   const deleteBatchBatch = (index: number): void => {
      setBatchBatches(original => original.filter((_, i) => i !== index))
   }

   const updateBatchBatches = (index: number, batchBatchProps: BatchBatchProps): void => {
      setBatchBatches([...batchBatches.slice(0, index), batchBatchProps, ...batchBatches.slice(index + 1)])
   }

   const [batchStock, setBatchStock] = useState<BatchStockProps[]>([])
   const [batchBatches, setBatchBatches] = useState<BatchBatchProps[]>([])

   useEffect(
      () => {
         if (id) {
            axios.get(`/stockOptions/${id}`)
               .then(response => setStockOptions(response.data))
               .catch(error => alert('Error loading stock options for batch' + error))
         } else {
            axios.get('/stockOptions')
               .then(response => setStockOptions(response.data))
               .catch(error => alert('Error loading stock options' + error))
         }
      }, [id]
   )

   useEffect(
      () => {
         if (id) {
            axios.get(`/batchOptions/${id}`)
               .then(response => setBatchOptions(response.data))
               .catch(error => alert('Error loading batch options for batch' + error))
         } else {
            axios.get('/batchOptions')
               .then(response => setBatchOptions(response.data))
               .catch(error => alert('Error loading batch options' + error))
         }
      }, [id]
   )

   useEffect(
      () => {
         if (id) {
            axios.get(`/batch/${id}`)
               .then(
                  (response) => {
                     const { data } = response

                     setBatch({
                        id: data.id,
                        name: data.name,
                        startTime: new Date(data.startTime),
                        visualCheck: data.visualCheck,
                        ph: data.ph,
                        brix: data.brix
                     })

                     data.batchStock && setBatchStock(data.batchStock)
                     if (data.batchStock && stockOptions) {
                        const bss = data.batchStock as StockOptionDto[]
                        setBatchStock(bss.map((bs, index) => { return { readOnly: props.readOnly, storedIndex: index, stockOptions: stockOptions, stockOption: { value: bs.value, label: bs.label, amount: bs.amount, unit: bs.unit }, amount: bs.amount, deleteHandler: deleteBatchStock, updateHandler: updateBatchStock } }))
                     }

                     data.batchBatches && setBatchBatches(data.batchBatches)
                     if (data.batchBatches && batchBatches) {
                        const bbs = data.batchBatches as BatchOptionDto[]
                        setBatchBatches(bbs.map((bb, index) => { return { readOnly: props.readOnly, storedIndex: index, batchOptions: batchOptions, batchOption: { value: bb.value, label: bb.label, amount: bb.amount }, amount: bb.amount, deleteHandler: deleteBatchBatch, updateHandler: updateBatchBatches } }))
                     }
                  })
               .catch(error => alert('Error loading batch' + error))
         }
      }, [id, stockOptions]
   )

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault()
      setIsSaving(true)
      setErrorMessage('')

      batch.batchStock = batchStock.map(bs => { return { value: bs.stockOption?.value, amount: bs.amount } as StockOptionDto })
      batch.batchBatches = batchBatches.map(bb => { return { value: bb.batchOption?.value, amount: bb.amount } as BatchOptionDto })

      if (id) {
         axios.put(`/batch/${id}`, batch)
            .then(() => history.push('/batchTake'))
            .catch((error) => {
               if (error.response.data) {
                  setErrorMessage(error.response.data)
               }
            })
            .finally(() => {
               setTimeout(function () {
                  setIsSaving(false)
               }, 1000)
            })
      } else {
         axios.post('/batch', batch)
            .then(() => {
               history.push('/batchTake')
            })
            .catch((error) => {
               if (error.response.data) {
                  setErrorMessage(error.response.data)
               }
            })
            .finally(() => {
               setTimeout(function () {
                  setIsSaving(false)
               }, 1000)
            })
      }
   }


   return (
      <>
         <form onSubmit={handleSubmit}>
            <h1>Brew Batches</h1>

            <ErrorMessage message={errorMessage}></ErrorMessage>

            <div className="form-group">

               <label>Batch Name</label>
               <input
                  type="text"
                  readOnly={props.readOnly} 
                  className="form-control"
                  value={batch.name}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setBatch({ ...batch, name: e.target.value })
                     }
                  }
               />

               { props.readOnly && <>
                  <br/>
                  <Link to={`/batchUse/${id}`}>
                     <button type="button" className="btn btn-warning">Batch Usage</button>
                  </Link>
                  <br/>
               </>
               }

            </div>

            

            <div className="form-group row">
               <div className="col-sm-10">
                  <label>Start Time</label>
               </div>

               <div className="col-sm-10">
                  <DatePicker
                     readOnly={props.readOnly} 
                     dateFormat="dd/MM/yyyy h:mm aa"
                     selected={batch.startTime}
                     onChange={date => setBatch({ ...batch, startTime: date })}
                     showTimeSelect
                  />
               </div>
            </div>

            <br />
            <h3>Stock used to make this batch</h3>
            <p>Only fresh stock is an option</p>
            { !props.readOnly &&
            <>
               <br />
               <button type="button" className="btn btn-success" onClick={() => addBatchStock()}>Add stock</button>
               <br />
            </>
            }
            
            <br />
            
            {batchStock.map((bs, index) => (
               <BatchStock key={index} readOnly={props.readOnly} storedIndex={index} stockOptions={stockOptions} stockOption={bs.stockOption} amount={bs.amount} deleteHandler={deleteBatchStock} updateHandler={updateBatchStock}></BatchStock>
            ))}

            <hr />
            <h3>Critical Check</h3>
            <br />


            <div className="form-group">
               <div className="form-check">
                  <input
                     type="checkbox"
                     disabled={props.readOnly} 
                     className="form-check-input"
                     checked={batch.visualCheck}
                     onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) => {
                           setBatch({ ...batch, visualCheck: e.target.checked })
                        }
                     }
                  />
                  <label className="form-check-label">
              Visual inspection of Steeping Tea at 80–90°C for 60 minutes check
                  </label>
               </div>
            </div>

            <br />
            <h3>Other batches used to make this batch</h3>
            <p>Only fresh batches are an option</p>
    
            { !props.readOnly &&
            <>
               <br />
               <button type="button" className="btn btn-success" onClick={() => addBatchBatch()}>Add batch</button>
               <br />
            </>}

            <br />
            {batchBatches.map((bb, index) => (
               <BatchBatch key={index} readOnly={props.readOnly} storedIndex={index} batchOptions={batchOptions} batchOption={bb.batchOption} amount={bb.amount} deleteHandler={deleteBatchBatch} updateHandler={updateBatchBatches}></BatchBatch>
            ))}

            <hr />
            <h3>Final Batch Specifications: </h3>
            <br />

            <div className="form-group col-md-3">
               <label>pH (2.5pH to 5.4pH)</label>
               <div className="input-group">
                  <input
                     type="number"
                     readOnly={props.readOnly} 
                     step="0.01"
                     className="form-control"
                     value={batch.ph}
                     onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) => {
                           setBatch({ ...batch, ph: parseFloat(e.target.value) })
                        }
                     }
                  />
                  <div className="input-group-append">
                     <span className="input-group-text">pH</span>
                  </div>
               </div>
            </div>

            <div className="form-group col-md-3">
               <label>Brix (2°Bx to 10°Bx)</label>
               <div className="input-group">
                  <input
                     type="number"
                     readOnly={props.readOnly} 
                     step="0.01"
                     className="form-control"
                     value={batch.brix}
                     onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) => {
                           setBatch({ ...batch, brix: parseFloat(e.target.value) })
                        }
                     }
                  />
                  <div className="input-group-append">
                     <span className="input-group-text">°Bx</span>
                  </div>
               </div>
            </div>

            { !props.readOnly &&
            <>
               <br />
               <div className="row">
                  <div className="col-md-12">
                     <SaveButton isSaving={isSaving}></SaveButton>
                  </div>
               </div>
            </>
            }
         </form>
      </>
   )
}

export default BatchForm