import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'

const GoogleCallback = () => {
   const history = useHistory()

   useEffect(
      () => {
         axios.get('/auth/google/callback?' + window.location.href.split('?')[1])
            .then((response) => { history.push(response.data) })
      }, [history]
   )

   return (
      <>
         <h3>Logging In</h3>
      </>
   )
}

export default GoogleCallback