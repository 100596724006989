import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import PageLoading from '../PageLoading'
import binImage from '../../images/bin.png'
import ErrorMessage from '../ErrorMessage'
import DatePicker from 'react-datepicker'

import { StockDto } from './stock.dto'

const StockList = () => {
   const now = new Date()
   const twoMonthsAgo = now.setDate( now.getDate() - 60 )
   
   const [stockItems, setStockItems] = useState<StockDto[]>()
   const [isLoading, setIsLoading] = useState<boolean>()
   const [errorMessage, setErrorMessage] = useState<string>()
   const [queryDate, setQueryDate] = useState<Date | null>(new Date())

   useEffect(
      () => {
         setIsLoading(true)
         setTimeout(function () {
            axios.get('/stock', { params: { queryDate } })
               .then(response => setStockItems(response.data))
               .catch(error => alert('Error loading stock: ' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         }, 1000)
      }, [queryDate]
   )

   const deleteStock = (stockId: number) => {
      setErrorMessage('')
      axios.delete(`/stock/${stockId}`)
         .then(() => {
            axios.get('/stock')
               .then(response => setStockItems(response.data))
               .catch(error => alert('Error loading stock: ' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         })
         .catch((error) => {
            if (error.response.data) {
               setErrorMessage(error.response.data)
            }
         })
   }

   return (
      <>
         {
            isLoading && <PageLoading></PageLoading>
         }

         {
            !isLoading && <>
               <h1>All Stock</h1>
          &nbsp;
          as of
          &nbsp;
               <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={queryDate}
                  onChange={date => setQueryDate(date)}
               />
          &nbsp;
               <ErrorMessage message={errorMessage}></ErrorMessage>

               <Link to='/stocktake'>
                  <button type="button" className="btn btn-info">Stocktake</button>
               </Link>
          &nbsp;
               <Link to='/stockAdd'>
                  <button type="button" className="btn btn-success">Add New Stock</button>
               </Link>

               <br /><br />

               <table className="table">
                  <thead>
                     <tr>
                        <th scope="col">Ingredient</th>
                        <th scope="col">Lot ID</th>
                        <th scope="col">Supplier</th>
                        <th scope="col">Remaining</th>
                        <th scope="col">Used</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">Invoice ID</th>
                        <th scope="col">Delete</th>
                     </tr>
                  </thead>
                  <tbody>
                     {stockItems && stockItems.map(
                        stock =>
                           <tr key={stock.id}>
                              {stock.deliveryTime && (new Date(stock.deliveryTime).getTime() < twoMonthsAgo) ?
                                 <th scope="row"><Link to={`/stockReadOnly/${stock.id}`}>{stock.ingredientOption.label}</Link></th>
                                 :
                                 <th scope="row"><Link to={`/stock/${stock.id}`}>{stock.ingredientOption.label}</Link></th>
                              }

                              <td>{stock.lotId}</td>
                              <td>{stock.supplierOption.label}</td>
                              <td className={(stock.remaining < 0) ? 'table-warning' : ''} >
                                 {stock.remaining} {stock.ingredientOption.unit}
                              </td>
                              <td><Link to={`/search/${stock.lotId}`}>{stock.used} of {stock.amount} {stock.ingredientOption.unit}</Link></td>
                              <td>{stock.deliveryTime && new Date(stock.deliveryTime).toLocaleDateString('en-AU')}</td>
                              <td><a target="_blank" rel="noopener noreferrer" href={`https://go.xero.com/Search/GlobalSearch/ViewAllResults?query=${stock.invoiceId}&filter=all`}>{stock.invoiceId}</a></td>
                              <td>
                                 <button type="button"
                                    className="btn btn-danger align-self-center delete-button"
                                    onClick={
                                       (e: React.MouseEvent<HTMLButtonElement>) => {
                                          if (stock.id) {
                                             if (window.confirm('Are you sure you wish to delete this stock item?')) deleteStock(stock.id)
                                          }
                                       }
                                    }
                                 >
                                    <img className="bin-image" src={binImage} alt="delete" />
                                 </button>
                              </td>
                           </tr>
                     )
                     }
                  </tbody>
               </table>
            </>
         }
      </>
   )
}

export default StockList
