import * as React from 'react'
import axios from 'axios'

export function createCtx<ContextType>() {
   const ctx = React.createContext<ContextType | undefined>(undefined)
   function useCtx() {
      const contextType = React.useContext(ctx)
      if (!contextType) throw new Error('useContext must be inside a provider with a value')
      return contextType
   }
   return [useCtx, ctx.Provider] as const
}

type AuthContextType = {
  isInitialized: boolean;
  isSignedIn: boolean;
  profileName: string;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
};

export const [useAuth, CtxProvider] = createCtx<AuthContextType>()

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
   const [isSignedIn, setIsSignedIn] = React.useState(false)

   const [isInitialized, setIsInitialized] = React.useState(false)

   const [profileName, setProfileName] = React.useState<string>('')

   // Check for signed in every second
   setInterval(() => {
      setProfileName(getCookieValue('profileName'))
   }, 1000)

   React.useEffect(() => {
      setIsSignedIn(profileName !== '')
      setIsInitialized(true)
   }, [profileName])

   const signIn = (): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
         axios.get('/auth/google/url')
            .then(response => window.location.href = response.data)
            .then(resolve)
            .catch(reject)
      })
   }

   const signOut = (): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
         axios.get('/auth/signOut')
            .then(() => { window.location.href = '/' })
            .then(resolve)
            .catch(reject)
      })
   }

   return <CtxProvider value={{ isInitialized, isSignedIn, profileName, signIn, signOut }}>{children}</CtxProvider>
}

const getCookieValue = (name: string) => {
   const value = '; ' + document.cookie
   const parts = value.split('; ' + name + '=')

   if (parts.length === 2) {
      const poppedPart = parts.pop()
      if (poppedPart) {
         const valueWithQuotes = poppedPart.split(';').shift()
         if (valueWithQuotes) {
            return valueWithQuotes.replace(/^"|"$/g, '')
         }
      }
   }
   return ''
}