import React from 'react'
import { Switch, Route, Redirect, Link} from 'react-router-dom'
import logo from './images/logo.png'

import './App.css'
import NavBar from './components/NavBar'

import StockList from './components/stock/StockList'
import Stocktake from './components/stock/Stocktake'
import StockForm from './components/stock/StockForm'
import BatchList from './components/batch/BatchList'
import BatchTake from './components/batch/BatchTake'
import BatchForm from './components/batch/BatchForm'
import BatchCalc from './components/batch/BatchCalc'
import BatchUse from './components/batch/BatchUse'
import SaleList from './components/sale/SaleList'
import SaleForm from './components/sale/SaleForm'
import Search from './components/search/Search'
import { useAuth } from './components/auth/AuthContext'
import GoogleCallback from './components/auth/GoogleCallback'

import axios from 'axios'

export default function App() {
   axios.defaults.baseURL = `${process.env['REACT_APP_SERVER_HOST']}/api` || 'http://localhost:3000/api'

   const { isSignedIn } = useAuth()

   return (
      <>
         <div className='text-center logo'>
            <img className='logo-img' src={logo} alt="Good Brew" />
         </div>

         <NavBar></NavBar>

         <div className="container-fluid">
            <Switch>
               {isSignedIn && (
                  <>
                     <Route exact path="/">
                        <Redirect to="/batchTake" />
                     </Route>
                     <Route path="/stockList">
                        <StockList />
                     </Route>
                     <Route path="/Stocktake">
                        <Stocktake />
                     </Route>
                     <Route path="/stock/:id">
                        <StockForm readOnly={false}/>
                     </Route>
                     <Route path="/stockReadOnly/:id">
                        <StockForm readOnly={true}/>
                     </Route>
                     <Route path="/stockAdd">
                        <StockForm readOnly={false} />
                     </Route>
                     <Route path="/batchList">
                        <BatchList />
                     </Route>
                     <Route path="/batchTake">
                        <BatchTake />
                     </Route>
                     <Route path="/batchCalc">
                        <BatchCalc />
                     </Route>
                     <Route path="/batch/:id">
                        <BatchForm readOnly={false}/>
                     </Route>
                     <Route path="/batchReadOnly/:id">
                        <BatchForm readOnly={true}/>
                     </Route>
                     <Route path="/batchAdd">
                        <BatchForm readOnly={false}/>
                     </Route>
                     <Route path="/batchUse/:id">
                        <BatchUse />
                     </Route>
                     <Route path="/saleList">
                        <SaleList />
                     </Route>
                     <Route path="/sale/:id">
                        <SaleForm readOnly={false} />
                     </Route>
                     <Route path="/saleReadOnly/:id">
                        <SaleForm readOnly={true}/>
                     </Route>
                     <Route path="/saleAdd">
                        <SaleForm readOnly={false} />
                     </Route>
                     <Route path="/search/:lotId?">
                        <Search />
                     </Route>
                  </>
               )}
               <Route path="/auth/google/callback">
                  <GoogleCallback />
               </Route>
            </Switch>
         </div>

         <br/>

         <div className="row justify-content-md-center" >
            <div className='col-md-2 text-center'><a href="https://www2.health.vic.gov.au/public-health/food-safety">Vic Food Safety Website</a></div>
            <div className='col-md-2 text-center'><a href="https://aco.net.au">ACO Website</a></div>
            <div className='col-md-2 text-center'><a href="https://docs.google.com/document/d/1LWoAOW4oBeFekkofsEC5vNICcXQSFiKAA24sLq8X4Kw">Food Safety Program</a></div>
            <div className='col-md-2 text-center'>Problems? Call Deano <a href="tel:+61430290952">0430290952</a></div>
         </div>
      </>
   )
}