import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'

import PageLoading from '../PageLoading'
import { BatchDto } from './batch.dto'
import { SaleDto } from '../sale/sale.dto'

interface ParamTypes {
  id: string
}

const BatchUse = () => {
   const [batch, setBatch] = useState<Partial<BatchDto>>()
   const [batchInBatches, setBatchInBatches] = useState<BatchDto[]>()
   const [batchInSales, setBatchInSales] = useState<SaleDto[]>()

   const [isLoading, setIsLoading] = useState<boolean>()
   const { id } = useParams<ParamTypes>()

   useEffect(
      () => {
         setIsLoading(true)
         if (id) {
            axios.get(`/batch/${id}`)
               .then(
                  (response) => {
                     const { data } = response

                     setBatch({
                        id: data.id,
                        name: data.name,
                        startTime: new Date(data.startTime),
                        visualCheck: data.visualCheck,
                        ph: data.ph,
                        brix: data.brix
                     })
                  })
               .catch(error => alert('Error loading batch' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         }
      }, [id]
   )

   useEffect(
      () => {
         setIsLoading(true)
         setTimeout(function () {
            axios.get(`/batchInBatches/${id}`)
               .then(response => setBatchInBatches(response.data))
               .catch(error => alert('Error loading batch in batches use: ' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         }, 1000)
      }, [id]
   )

   useEffect(
      () => {
         setIsLoading(true)
         setTimeout(function () {
            axios.get(`/batchInSales/${id}`)
               .then(response => setBatchInSales(response.data))
               .catch(error => alert('Error loading batch in sales use: ' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         }, 1000)
      }, [id]
   )

   return (
      <>
         {
            isLoading && <PageLoading></PageLoading>
         }

         {
            !isLoading && <>

               <h1>Batch: <Link to={`/batchReadOnly/${id}`}>{batch && batch.name}</Link></h1>
               &nbsp;
               <h3>Has been used in these batches as a mother</h3>

               <table className="table">
                  <thead>
                     <tr>
                        <th scope="col">Batch Name</th>
                        <th scope="col">Used</th>
                        <th scope="col">Start Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     {batchInBatches && batchInBatches.map(
                        batchInBatch =>
                           <tr key={batchInBatch.id}>
                              <th scope="row">
                                 <Link to={`/batchReadOnly/${batchInBatch.id}`}>
                                    {batchInBatch.name}
                                 </Link>
                              </th>
                              <td>
                                 {batchInBatch.amount} L
                              </td>
                              <td>
                                 <td>{batchInBatch.startTime && new Date(batchInBatch.startTime).toLocaleDateString('en-AU')}</td>
                              </td>
                           </tr>
                     )
                     }
                  </tbody>
               </table>

               <br/>
               <br/>
               
               <h3>Used in these sales as kombucha</h3>

               <table className="table">
                  <thead>
                     <tr>
                        <th scope="col">Sale Name</th>
                        <th scope="col">Used</th>
                        <th scope="col">Pickup Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     {batchInSales && batchInSales.map(
                        batchInSale =>
                           <tr key={batchInSale.id}>
                              <th scope="row"><Link to={`/sale/${batchInSale.id}`}>{batchInSale.name}</Link></th>
                              <td>
                                 {batchInSale.saleBatches[0].amount} L
                              </td>
                              <td>
                                 <td>{batchInSale.pickupTime && new Date(batchInSale.pickupTime).toLocaleDateString('en-AU')}</td>
                              </td>
                           </tr>
                     )
                     }
                  </tbody>
               </table>

            </>
         }
      </>
   )
}

export default BatchUse
