import React from 'react'
import ReactSelect, { ValueType } from 'react-select'

import binImage from '../../images/bin.png'
import { StockOptionDto } from './batch.dto'

export type BatchStockProps = {
  storedIndex: number,
  stockOptions?: StockOptionDto[],
  stockOption?: StockOptionDto | null,
  amount: number,
  deleteHandler: (index: number) => void,
  updateHandler: (index: number, stockOption: BatchStockProps) => void
  readOnly: boolean
}

const BatchStock = (props: BatchStockProps) => {
   return (
      <>
         {
            <div className="nested-fieldset">
               <div className="row">
                  <div className="col-md-5">
                     <label>Stock #{props.storedIndex + 1}</label>
                     <ReactSelect
                        value={props.stockOption}
                        isDisabled={props.readOnly}
                        options={props.stockOptions}
                        onChange={
                           (selectedOption: ValueType<StockOptionDto, false>) => {
                              const value = (selectedOption as StockOptionDto)
                              props.updateHandler(props.storedIndex, { ...props, stockOption: value })
                           }
                        }
                     />
                  </div>

                  <div className="form-group col-md-5">
                     <label>Amount Used</label>
                     <div className="input-group">
                        <input
                           type="number"
                           step="0.01"
                           className="form-control"
                           value={props.amount}
                           readOnly={props.readOnly}
                           onChange={
                              (e: React.ChangeEvent<HTMLInputElement>) => {
                                 const value = parseFloat(e.target.value)
                                 props.updateHandler(props.storedIndex, { ...props, amount: value })
                              }
                           }
                        />
                        <div className="input-group-append">
                           <span className="input-group-text">{props.stockOption?.unit}</span>
                        </div>
                     </div>
                  </div>


                  { !props.readOnly &&
            <>
               <div className="col-md-1 d-flex">

                  <button type="button"
                     className="btn btn-danger align-self-center delete-button"
                     onClick={
                        (e: React.MouseEvent<HTMLButtonElement>) => {
                           props.deleteHandler(props.storedIndex)
                        }
                     }
                  >
                     <img className="bin-image" src={binImage} alt="delete" />
                  </button>
               </div>
            </>
                  }

               </div>
            </div>
         }
      </>
   )
}

export default BatchStock
