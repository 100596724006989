import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import { AuthProvider } from './components/auth/AuthContext'

ReactDOM.render(
   <React.StrictMode>
      <AuthProvider>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </AuthProvider>
   </React.StrictMode>,

   document.getElementById('root')
)