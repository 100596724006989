import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useAuth } from './auth/AuthContext'

const NavBar = () => {
   const { isSignedIn, profileName, signIn, signOut } = useAuth()
   const history = useHistory()

   return (
      <>
         {isSignedIn && <>
            <div className="row justify-content-md-center" >
               <div className='col-md-2'>
                  <NavLink to='/stocktake' activeClassName="navLinkSelected" isActive={(_, location): boolean => location.pathname.includes('stock')}>
                     <button type="button" className="navLinkButton btn btn-success btn-lg btn-block">Stock</button>
                  </NavLink>
               </div>
               <div className='col-md-2'>
                  <NavLink to='/batchTake' activeClassName="navLinkSelected" isActive={(_, location): boolean => location.pathname.includes('batch')}>
                     <button type="button" className="navLinkButton btn btn-warning btn-lg btn-block">Batches</button>
                  </NavLink>
               </div>
               <div className='col-md-2'>
                  <NavLink to='/saleList' activeClassName="navLinkSelected" isActive={(_, location): boolean => location.pathname.includes('sale') }>
                     <button type="button" className="navLinkButton btn btn-danger btn-lg btn-block">Sales</button>
                  </NavLink>
               </div>
               <div className='col-md-2'>
                  <NavLink to='/search' activeClassName="navLinkSelected">
                     <button type="button" className="navLinkButton btn btn-info btn-lg btn-block">Search</button>
                  </NavLink>
               </div>
               <div className='col-md-2'>
                  {isSignedIn && (
                     <button onClick={() => { signOut().then(() => history.push('/')) }} className="navLinkButton btn btn-primary btn-lg btn-block">Sign Out</button>
                  )}
               </div>
            </div>
            <div className="row justify-content-end" >
               <div className='col-md-4'>
                  {isSignedIn && <> Signed in as:&nbsp;
                     {profileName}
                  </>
                  }
               </div>
            </div>
         </>
         }

         {!isSignedIn && (
            <div className="row justify-content-md-center" >

               <div className='col-md-2'>
                  <button onClick={() => signIn()} className="navLinkButton btn btn-primary btn-lg btn-block">Sign In</button>
               </div>
            </div>
         )}
      </>
   )
}

export default NavBar
