import React, { useState, useEffect } from 'react'

const BatchCalc = () => {
   const [currentVolume, setCurrentVolume] = useState<number>()
   const [currentAcid, setCurrentAcid] = useState<number>()
   const [currentSugar, setCurrentSugar] = useState<number>()
   const [desiredSugar, setDesiredSugar] = useState<number>(40)
   const [desiredAcid, setDesiredAcid] = useState<number>(20)

   const [totalCurrentAcid, setTotalCurrentAcid] = useState<number>()
   const [totalCurrentSugar, setTotalCurrentSugar] = useState<number>()
   const [desiredVolume, setDesiredVolume] = useState<number>()
   const [totalDesiredSugar, setTotalDesiredSugar] = useState<number>()
   const [sugarRequired, setSugarRequired] = useState<number>()

   useEffect(
      () => {
         if (currentVolume && currentAcid) {
            setTotalCurrentAcid(currentVolume * currentAcid / 1000)
         }
      }, [currentVolume, currentAcid]
   )

   useEffect(
      () => {
         if (currentVolume && currentSugar) {
            setTotalCurrentSugar(currentVolume * currentSugar / 1000)
         }
      }, [currentVolume, currentSugar]
   )

   useEffect(
      () => {
         if (totalCurrentAcid) {
            setDesiredVolume(totalCurrentAcid / desiredAcid * 1000)
         }
      }, [totalCurrentAcid, desiredAcid]
   )

   useEffect(
      () => {
         if (desiredVolume) {
            setTotalDesiredSugar(desiredVolume * desiredSugar / 1000)
         }
      }, [desiredVolume, desiredSugar]
   )

   useEffect(
      () => {
         if (totalDesiredSugar && totalCurrentSugar) {
            setSugarRequired(totalDesiredSugar - totalCurrentSugar)
         }
      }, [totalDesiredSugar, totalCurrentSugar]
   )

   return (
      <>
         <h1>Batch Calc:</h1>

         <hr />

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Current Volume:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={currentVolume}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setCurrentVolume(parseFloat(e.target.value))
                     }
                  }
               />
               <div className="input-group-append">
                  <span className="input-group-text">L</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Current Acid:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={currentAcid}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setCurrentAcid(parseFloat(e.target.value))
                     }
                  }
               />
               <div className="input-group-append">
                  <span className="input-group-text">g/L</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Current Sugar:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={currentSugar}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setCurrentSugar(parseFloat(e.target.value))
                     }
                  }
               />
               <div className="input-group-append">
                  <span className="input-group-text">g/L</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Desired Sugar:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={desiredSugar}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setDesiredSugar(parseFloat(e.target.value))
                     }
                  }
               />
               <div className="input-group-append">
                  <span className="input-group-text">g/L</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Desired Acid:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={desiredAcid}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setDesiredAcid(parseFloat(e.target.value))
                     }
                  }
               />
               <div className="input-group-append">
                  <span className="input-group-text">g/L</span>
               </div>
            </div>
         </div>

         <hr />

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Total Current Acid:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={totalCurrentAcid && totalCurrentAcid.toFixed(2)}
                  readOnly
               />
               <div className="input-group-append">
                  <span className="input-group-text">Kg</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Total Current Sugar:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={totalCurrentSugar && totalCurrentSugar.toFixed(2)}
                  readOnly
               />
               <div className="input-group-append">
                  <span className="input-group-text">Kg</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Desired Volume:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={desiredVolume && desiredVolume.toFixed(2)}
                  readOnly
               />
               <div className="input-group-append">
                  <span className="input-group-text">L</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Total Desired Sugar:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={totalDesiredSugar && totalDesiredSugar.toFixed(2)}
                  readOnly
               />
               <div className="input-group-append">
                  <span className="input-group-text">Kg</span>
               </div>
            </div>
         </div>

         <div className="form-group col-md-6">
            <div className="input-group">
               <label>Sugar Required:</label>
          &nbsp;
               <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  value={sugarRequired && sugarRequired.toFixed(2)}
                  readOnly
               />
               <div className="input-group-append">
                  <span className="input-group-text">Kg</span>
               </div>
            </div>
         </div>
      </>
   )
}

export default BatchCalc