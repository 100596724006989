import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import PageLoading from '../PageLoading'
import binImage from '../../images/bin.png'
import ErrorMessage from '../ErrorMessage'


import { SaleDto } from './sale.dto'

const SaleList = () => {
   const now = new Date()
   const twoMonthsAgo = now.setDate( now.getDate() - 60 )
  
   const [sales, setSales] = useState<SaleDto[]>()
   const [isLoading, setIsLoading] = useState<boolean>()
   const [errorMessage, setErrorMessage] = useState<string>()

   useEffect(
      () => {
         setIsLoading(true)
         setTimeout(function () {
            axios.get('/sale')
               .then(response => setSales(response.data))
               .catch(error => alert('Error loading sale' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         }, 1000)
      }, []
   )

   const deleteSale = (saleId: number) => {
      setErrorMessage('')
      axios.delete(`/sale/${saleId}`)
         .then(() => {
            axios.get('/sale')
               .then(response => setSales(response.data))
               .catch(error => alert('Error loading sale: ' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         })
         .catch((error) => {
            if (error.response.data) {
               setErrorMessage(error.response.data)
            }
         })
   }

   return (
      <>
         {
            isLoading && <PageLoading></PageLoading>
         }

         {
            !isLoading && <>
               <h1>Sales</h1>

               <ErrorMessage message={errorMessage}></ErrorMessage>

               <Link to='/saleAdd'>
                  <button type="button" className="btn btn-success">Add New Sale</button>
               </Link>

               <br /><br />

               <table className="table">
                  <thead>
                     <tr>
                        <th scope="col">Description</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Pickup Time</th>
                        <th scope="col">Invoice ID</th>
                        <th scope="col">Delete</th>
                     </tr>
                  </thead>
                  <tbody>
                     {sales && sales.map(
                        sale =>
                           <tr key={sale.id}>

                              {sale.pickupTime && (new Date(sale.pickupTime).getTime() < twoMonthsAgo) ?
                                 <th scope="row"><Link to={`/saleReadOnly/${sale.id}`}>{sale.name}</Link></th>
                                 :
                                 <th scope="row"><Link to={`/sale/${sale.id}`}>{sale.name}</Link></th>
                              }

                              <td>{sale.customerOption.label}</td>
                              <td>{sale.pickupTime && new Date(sale.pickupTime).toLocaleDateString('en-AU')}</td>
                              <td><a target="_blank" rel="noopener noreferrer" href={`https://go.xero.com/Search/GlobalSearch/ViewAllResults?query=${sale.invoiceId}&filter=all`}>{sale.invoiceId}</a></td>
                              <td>
                                 <button type="button"
                                    className="btn btn-danger align-self-center delete-button"
                                    onClick={
                                       (e: React.MouseEvent<HTMLButtonElement>) => {
                                          if (sale.id) {
                                             if (window.confirm('Are you sure you wish to delete this sale item?')) deleteSale(sale.id)
                                          }
                                       }
                                    }
                                 >
                                    <img className="bin-image" src={binImage} alt="delete" />
                                 </button>


                              </td>
                           </tr>
                     )
                     }
                  </tbody>
               </table>
            </>
         }
      </>
   )
}

export default SaleList
