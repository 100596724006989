import React from 'react'

const PageLoading = () =>
   <>
      <div className="row">
         <div className="col">
        &nbsp;
         </div>
      </div>

      <div className="row">
         <div className="col">
        &nbsp;
         </div>
      </div>

      <div className="row">
         <div className="col">
        &nbsp;
         </div>
      </div>

      <div className="row">
         <div className="col">
            <div className="d-flex justify-content-center">
               <div className="spinner-grow text-primary" />
          &nbsp;&nbsp;
               <div className="spinner-grow text-secondary" />
          &nbsp;&nbsp;
               <div className="spinner-grow text-success" />
          &nbsp;&nbsp;
               <div className="spinner-grow text-danger" />
          &nbsp;&nbsp;
               <div className="spinner-grow text-warning" />
            </div>
         </div>
      </div>
   </>

export default PageLoading
