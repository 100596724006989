import React from 'react'
import ReactSelect, { ValueType } from 'react-select'

import binImage from '../../images/bin.png'
import { BatchOptionDto } from './sale.dto'

export type SaleBatchProps = {
  storedIndex: number,
  batchOptions?: BatchOptionDto[],
  batchOption?: BatchOptionDto | null,
  amount: number,
  deleteHandler: (index: number) => void,
  updateHandler: (index: number, stockOption: SaleBatchProps) => void
  readOnly: boolean
}

const BatchBatch = (props: SaleBatchProps) => {
   return (
      <>
         {
            <div className="nested-fieldset">
               <div className="row">
                  <div className="col-md-5">
                     <label>Batch #{props.storedIndex + 1}</label>
                     <ReactSelect
                        value={props.batchOption}
                        isDisabled={props.readOnly}
                        options={props.batchOptions}
                        onChange={
                           (selectedOption: ValueType<BatchOptionDto, false>) => {
                              const value = (selectedOption as BatchOptionDto)
                              props.updateHandler(props.storedIndex, { ...props, batchOption: value })
                           }
                        }
                     />
                  </div>


                  <div className="form-group col-md-5">
                     <label>Amount Used</label>
                     <div className="input-group">
                        <input
                           type="number"
                           step="0.01"
                           className="form-control"
                           value={props.amount}
                           readOnly={props.readOnly}
                           onChange={
                              (e: React.ChangeEvent<HTMLInputElement>) => {
                                 const value = parseFloat(e.target.value)
                                 props.updateHandler(props.storedIndex, { ...props, amount: value })
                              }
                           }
                        />
                        <div className="input-group-append">
                           <span className="input-group-text">L</span>
                        </div>
                     </div>
                  </div>


                  <div className="col-md-1 d-flex">

                     <button type="button"
                        className="btn btn-danger align-self-center delete-button"
                        onClick={
                           (e: React.MouseEvent<HTMLButtonElement>) => {
                              props.deleteHandler(props.storedIndex)
                           }
                        }
                     >
                        <img className="bin-image" src={binImage} alt="delete" />
                     </button>
                  </div>
               </div>
            </div>
         }
      </>
   )
}

export default BatchBatch
