import React, { useRef, useEffect } from 'react'

interface ErrorMessageProps {
  message?: string,
}
const ErrorMessage = (props: ErrorMessageProps) => {
   const errorMessageDivRef = useRef<HTMLDivElement>(null)

   useEffect(
      () => {
         if (props.message) {
            if (errorMessageDivRef.current) {
               window.scrollTo(0, errorMessageDivRef.current.offsetTop)
            }
         }
      }, [props.message]
   )

   return <>
      {
         props.message && <>
            <div ref={errorMessageDivRef} id="errorMessage" className="alert alert-danger" role="alert">
               {props.message}
            </div>
         </>
      }
   </>
}

export default ErrorMessage
