import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import DatePicker from 'react-datepicker'
import ReactSelect, { ValueType } from 'react-select'
import axios from 'axios'

import { SaleDto, CustomerOptionDto } from './sale.dto'
import { BatchOptionDto } from '../batch/batch.dto'
import SaleBatch, { SaleBatchProps } from './SaleBatch'

import ErrorMessage from '../ErrorMessage'
import SaveButton from '../SaveButton'

interface ParamTypes {
  id: string
}

type SaleFormProps = {
  readOnly: boolean
 }

const SaleForm = (props: SaleFormProps) => {
   const now = new Date()
   const saleName = now.getFullYear().toString() + ('0' + (now.getMonth() + 1)).slice(-2) + ('0' + now.getDate()).slice(-2)

   const [sale, setSale] = useState<Partial<SaleDto>>({ name: 'SF' + saleName, pickupTime: new Date() })

   const [errorMessage, setErrorMessage] = useState<string>()
   const [customerOptions, setCustomerOptions] = useState<CustomerOptionDto[]>()
   const [batchOptions, setBatchOptions] = useState<BatchOptionDto[]>()

   const [isSaving, setIsSaving] = useState<boolean>(false)
   const history = useHistory()
   const { id } = useParams<ParamTypes>()

   const addSaleBatch = () => {
      const newSaleBatch = { storedIndex: -1, readOnly: props.readOnly, batchOptions: batchOptions, batchOption: { value: -1, label: '', amount: 0 }, amount: 0, deleteHandler: deleteSaleBatch, updateHandler: updateSaleBatches }
      setSaleBatches(original => [...original, newSaleBatch])
   }

   const deleteSaleBatch = (index: number): void => {
      setSaleBatches(original => original.filter((_, i) => i !== index))
   }

   const updateSaleBatches = (index: number, saleBatchProps: SaleBatchProps): void => {
      setSaleBatches([...saleBatches.slice(0, index), saleBatchProps, ...saleBatches.slice(index + 1)])
   }
   const [saleBatches, setSaleBatches] = useState<SaleBatchProps[]>([])

   useEffect(
      () => {
         axios.get('/batchOptions')
            .then(response => setBatchOptions(response.data))
            .catch(error => alert('Error loading batch options' + error))
      }, []
   )

   useEffect(
      () => {
         axios.get('/customerOptions')
            .then(response => setCustomerOptions(response.data))
            .catch(error => alert('Error loading customer options' + error))
      }, []
   )

   useEffect(
      () => {
         if (id) {
            axios.get(`/sale/${id}`)
               .then(
                  (response) => {
                     const { data } = response

                     setSale({
                        id: data.id,
                        name: data.name,
                        customerOption: data.customerOption,
                        invoiceId: data.invoiceId,
                        pickupTime: new Date(data.pickupTime)
                     })

                     data.saleBatches && setSaleBatches(data.saleBatches)
                     if (data.saleBatches && saleBatches) {
                        const sbs = data.saleBatches as BatchOptionDto[]
                        setSaleBatches(sbs.map((sb, index) => { return { readOnly: props.readOnly, storedIndex: index, batchOptions: batchOptions, batchOption: { value: sb.value, label: sb.label, amount: sb.amount }, amount: sb.amount, deleteHandler: deleteSaleBatch, updateHandler: updateSaleBatches } }))
                     }

                  })
               .catch(error => alert('Error loading sale' + error))
         }
      }, [id]
   )

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault()
      setIsSaving(true)
      setErrorMessage('')

      sale.saleBatches = saleBatches.map(sb => { return { value: sb.batchOption?.value, amount: sb.amount } as BatchOptionDto })

      if (id) {
         axios.put(`/sale/${id}`, sale)
            .then(() => history.push('/saleList'))
            .catch((error) => {
               if (error.response.data) {
                  setErrorMessage(error.response.data)
               }
            })
            .finally(() => {
               setTimeout(function () {
                  setIsSaving(false)
               }, 1000)
            })
      } else {
         axios.post('/sale', sale)
            .then(() => {
               history.push('/saleList')
            })
            .catch((error) => {
               if (error.response.data) {
                  setErrorMessage(error.response.data)
               }
            })
            .finally(() => {
               setTimeout(function () {
                  setIsSaving(false)
               }, 1000)
            })
      }
   }


   return (
      <>
         <form onSubmit={handleSubmit}>
            <h1>Sale</h1>

            <ErrorMessage message={errorMessage}></ErrorMessage>

            <div className="form-group">

               <label>Description</label>
               <input
                  type="text"                   
                  readOnly={props.readOnly} 
                  className="form-control"
                  value={sale.name}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSale({ ...sale, name: e.target.value })
                     }
                  }
               />
            </div>

            <div className="form-group">
               <label>Customer</label>
               <ReactSelect
                  value={sale.customerOption}
                  isDisabled={props.readOnly}
                  options={customerOptions}
                  onChange={
                     (selectedOption: ValueType<CustomerOptionDto, false>) => {
                        const value = (selectedOption as CustomerOptionDto)
                        setSale({ ...sale, customerOption: value })
                     }
                  }
               />
            </div>

            <div className="form-group">
               <label>Invoice ID (completed by the office team)</label>
               <input
                  type="text"
                  readOnly={props.readOnly} 
                  className="form-control"
                  value={sale.invoiceId}
                  onChange={
                     (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSale({ ...sale, invoiceId: e.target.value })
                     }
                  }
               />
            </div>

            <br />
            <h3>Batches used in this sale</h3>
            <br />
            <button type="button" className="btn btn-success" onClick={() => addSaleBatch()}>Add batch</button>
            <br /><br />
            {saleBatches.map((sb, index) => (
               <SaleBatch key={index} readOnly={props.readOnly} storedIndex={index} batchOptions={batchOptions} batchOption={sb.batchOption} amount={sb.amount} deleteHandler={deleteSaleBatch} updateHandler={updateSaleBatches}></SaleBatch>
            ))}


            <br />
            <label>Pick Up</label>
            <br />

            <div className="form-group row">
               <div className="col-sm-10">
                  <label>Time of pickup</label>
               </div>

               <div className="col-sm-10">
                  <DatePicker
                     readOnly={props.readOnly} 
                     dateFormat="dd/MM/yyyy h:mm aa"
                     selected={sale.pickupTime}
                     onChange={date => setSale({ ...sale, pickupTime: date })}
                     showTimeSelect
                  />
               </div>
            </div>

            <br />
            { !props.readOnly &&
            <>
               <br />
               <div className="row">
                  <div className="col-md-12">
                     <SaveButton isSaving={isSaving}></SaveButton>
                  </div>
               </div>
            </>
            }
         </form>
      </>
   )
}

export default SaleForm
