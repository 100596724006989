import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import PageLoading from '../PageLoading'
import binImage from '../../images/bin.png'
import ErrorMessage from '../ErrorMessage'
import DatePicker from 'react-datepicker'

import { BatchDto } from './batch.dto'

const Batchtake = () => {
   const now = new Date()
   const twoMonthsAgo = now.setDate( now.getDate() - 60 )

   const [batches, setBatches] = useState<BatchDto[]>()
   const [isLoading, setIsLoading] = useState<boolean>()
   const [errorMessage, setErrorMessage] = useState<string>()
   const [queryDate, setQueryDate] = useState<Date | null>(new Date())

   useEffect(
      () => {
         setIsLoading(true)
         setTimeout(function () {
            axios.get('/batch', { params: { queryDate } })
               .then(response => setBatches(response.data))
               .catch(error => alert('Error loading batches: ' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         }, 1000)
      }, [queryDate]
   )

   const deleteBatch = (batchId: number) => {
      setErrorMessage('')
      axios.delete(`/batch/${batchId}`)
         .then(() => {
            axios.get('/batch')
               .then(response => setBatches(response.data))
               .catch(error => alert('Error loading batches: ' + error))
               .finally(() => {
                  setIsLoading(false)
               })
         })
         .catch((error) => {
            if (error.response.data) {
               setErrorMessage(error.response.data)
            }
         })
   }

   return (
      <>
         {
            isLoading && <PageLoading></PageLoading>
         }

         {
            !isLoading && <>
               <h1>BatchTake (Stocktake of Batches)</h1>
          &nbsp;
          as of
          &nbsp;
               <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={queryDate}
                  onChange={date => setQueryDate(date)}
               />
          &nbsp;

               <ErrorMessage message={errorMessage}></ErrorMessage>

               <Link to='/batchList'>
                  <button type="button" className="btn btn-info">All Batches</button>
               </Link>
          &nbsp;
               <Link to='/batchCalc'>
                  <button type="button" className="btn btn-warning">Calc</button>
               </Link>
          &nbsp;
               <Link to='/batchAdd'>
                  <button type="button" className="btn btn-success">Add New Batch</button>
               </Link>

               <br /><br />

               <table className="table">
                  <thead>
                     <tr>
                        <th scope="col">Batch Name</th>
                        <th scope="col">Remaining</th>
                        <th scope="col">Used</th>
                        <th scope="col">Time</th>
                        <th scope="col">Delete</th>
                     </tr>
                  </thead>
                  <tbody>

                     {batches &&
                <tr>
                   <th scope="row">Total</th>
                   <th >
                      {batches.reduce((accumulator, batch) => accumulator + batch.remaining, 0)} L
                   </th>
                </tr>
                     }

                     {batches && batches.map(
                        batch =>
                           batch.remaining > 0 &&
                  <tr key={batch.id}>

                     {batch.startTime && (new Date(batch.startTime).getTime() < twoMonthsAgo) ?
                        <th scope="row"><Link to={`/batchReadOnly/${batch.id}`}>{batch.name}</Link></th>
                        :
                        <th scope="row"><Link to={`/batch/${batch.id}`}>{batch.name}</Link></th>
                     }

                     <td className={(batch.remaining < 0) ? 'table-warning' : ''} >
                        {batch.remaining} L
                     </td>
                     <td><Link to={`/batchUse/${batch.id}`}>{batch.used} of {batch.amount} L </Link></td>
                     <td>{batch.startTime && new Date(batch.startTime).toLocaleDateString('en-AU')}</td>
                     <td>
                        <button type="button"
                           className="btn btn-danger align-self-center delete-button"
                           onClick={
                              (e: React.MouseEvent<HTMLButtonElement>) => {
                                 if (batch.id) {
                                    if (window.confirm('Are you sure you wish to delete this batch item?')) deleteBatch(batch.id)
                                 }
                              }
                           }
                        >
                           <img className="bin-image" src={binImage} alt="delete" />
                        </button>
                     </td>
                  </tr>
                     )
                     }
                  </tbody>
               </table>
            </>
         }
      </>
   )
}

export default Batchtake
