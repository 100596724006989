import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import DatePicker from 'react-datepicker'
import ReactSelect, { ValueType } from 'react-select'
import axios from 'axios'

import { StockDto, IngredientOptionDto, SupplierOptionDto } from './stock.dto'

import ErrorMessage from '../ErrorMessage'
import SaveButton from '../SaveButton'

type StockFormProps = {
  readOnly: boolean
 }

interface ParamTypes {
  id: string
}

const StockForm = (props: StockFormProps) => {
   const now = new Date()
   const nextYear = now.setFullYear(now.getFullYear() + 2)

   const [stock, setStock] = useState<Partial<StockDto>>({ deliveryTime: new Date(), expiryDate: new Date(nextYear) })
   const [errorMessage, setErrorMessage] = useState<string>()

   const [ingredientOptions, setIngredientOptions] = useState<IngredientOptionDto[]>()
   const [supplierOptions, setSupplierOptions] = useState<SupplierOptionDto[]>()

   const [isSaving, setIsSaving] = useState<boolean>(false)

   const history = useHistory()
   const { id } = useParams<ParamTypes>()

   useEffect(
      () => {
         axios.get('/ingredientOptions')
            .then(response => setIngredientOptions(response.data))
            .catch(error => alert('Error loading ingredient options' + error))
      }, []
   )

   useEffect(
      () => {
         axios.get('/supplierOptions')
            .then(response => setSupplierOptions(response.data))
            .catch(error => alert('Error loading supplier options' + error))
      }, []
   )

   useEffect(
      () => {
         if (id) {
            axios.get(`/stock/${id}`)
               .then(
                  (response) => {
                     const { data } = response

                     setStock({
                        deliveryTime: new Date(data.deliveryTime),
                        expiryDate: new Date(data.expiryDate),
                        ingredientOption: data.ingredientOption,
                        supplierOption: data.supplierOption,
                        lotId: data.lotId,
                        amount: data.amount,
                        vehicleCheck: data.vehicleCheck,
                        qualityCheck: data.qualityCheck,
                        invoiceId: data.invoiceId
                     })
                  })
               .catch(error => alert('Error loading ingredient' + error))
         }
      }, [id]
   )

  interface IdParam {
    id: string
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
     e.preventDefault()
     setIsSaving(true)
     setErrorMessage('')

     if (id) {
        axios.put(`/stock/${id}`, stock)
           .then(() => history.push('/stocktake'))
           .catch((error) => {
              if (error.response.data) {
                 setErrorMessage(error.response.data)
              }
           })
           .finally(() => {
              setTimeout(function () {
                 setIsSaving(false)
              }, 1000)
           })
     } else {
        axios.post('/stock', stock)
           .then(() => {
              history.push('/stocktake')
           })
           .catch((error) => {
              if (error.response.data) {
                 setErrorMessage(error.response.data)
              }
           })
           .finally(() => {
              setTimeout(function () {
                 setIsSaving(false)
              }, 1000)
           })
     }
  }

  return (
     <>
        <form onSubmit={handleSubmit}>
           <h1>Stock</h1>

           <ErrorMessage message={errorMessage}></ErrorMessage>

           <div className="form-group row">
              <div className="col-sm-10">
                 <label>Delivery Time</label>
              </div>

              <div className="col-sm-10">
                 <DatePicker
                    readOnly={props.readOnly} 
                    dateFormat="dd/MM/yyyy h:mm aa"
                    selected={stock.deliveryTime}
                    onChange={date => setStock({ ...stock, deliveryTime: date })}
                    showTimeSelect
                 />
              </div>
           </div>

           <div className="form-group">
              <label>Ingredient</label>
              <ReactSelect
                 isDisabled={props.readOnly}
                 value={stock.ingredientOption}
                 options={ingredientOptions}
                 onChange={
                    (selectedOption: ValueType<IngredientOptionDto, false>) => {
                       const value = (selectedOption as IngredientOptionDto)
                       setStock({ ...stock, ingredientOption: value })
                    }
                 }
              />
           </div>

           <div className="form-group">
              <label>Supplier</label>
              <ReactSelect
                 isDisabled={props.readOnly}
                 value={stock.supplierOption}
                 options={supplierOptions}
                 onChange={
                    (selectedOption: ValueType<SupplierOptionDto, false>) => {
                       const value = (selectedOption as SupplierOptionDto)
                       setStock({ ...stock, supplierOption: value })
                    }
                 }
              />
           </div>

           <div className="form-group">
              <label>Lot ID (For water enter the date)</label>
              <input
                 type="text"
                 readOnly={props.readOnly} 
                 className="form-control"
                 value={stock.lotId}
                 onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                       setStock({ ...stock, lotId: e.target.value })
                    }
                 }
              />
           </div>

           <label>Amount</label>
           <div className="input-group">
              <input
                 type="number"
                 step="0.01"
                 readOnly={props.readOnly} 
                 className="form-control"
                 value={stock.amount}
                 onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                       setStock({ ...stock, amount: parseFloat(e.target.value) })
                    }
                 }
              />

              <div className="input-group-append">
                 <span className="input-group-text">{stock.ingredientOption?.unit}</span>
              </div>

           </div>

           <div className="form-group row">
              <div className="col-sm-10">
                 <label>Expiry Date</label>
              </div>
              <div className="col-sm-10">
                 <DatePicker
                    readOnly={props.readOnly} 
                    dateFormat="dd/MM/yyyy"
                    selected={stock.expiryDate}
                    onChange={date => setStock({ ...stock, expiryDate: date })}
                 />
              </div>
           </div>

           <div className="form-check">
              <input
                 type="checkbox"
                 disabled={props.readOnly}
                 className="form-check-input"
                 checked={stock.vehicleCheck}
                 onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                       setStock({ ...stock, vehicleCheck: e.target.checked })
                    }
                 }
              />
              <label className="form-check-label">
            Delivery Vehicle Check
              </label>
           </div>

           <div className="form-check">
              <input
                 type="checkbox"
                 disabled={props.readOnly} 
                 className="form-check-input"
                 checked={stock.qualityCheck}
                 onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                       setStock({ ...stock, qualityCheck: e.target.checked })
                    }
                 }
              />
              <label className="form-check-label">
            Ingredient Integrity and Quality Check
              </label>
           </div>

           <label>Invoice ID (Enter if possible, will be confirmed by office)</label>
           <input
              type="text"
              disabled={props.readOnly} 
              className="form-control"
              value={stock.invoiceId}
              onChange={
                 (e: React.ChangeEvent<HTMLInputElement>) => {
                    setStock({ ...stock, invoiceId: e.target.value })
                 }
              }
           />
           <br />
           { !props.readOnly &&
            <>
               <br />
               <div className="row">
                  <div className="col-md-12">
                     <SaveButton isSaving={isSaving}></SaveButton>
                  </div>
               </div>
            </>
           }
        </form>
     </>
  )
}

export default StockForm
